<template>
  <div >

      <div class="otherNotePlan">
        <!-- <base-button label="生成"  icon="iconfont iconshengcheng" @click="generateRepaymentPlan" /> -->
         <div v-if="!isDetail" class="addbtn">
           <!-- 必须先生成才有新增 -->
            <div class="addbutton" @click="addPlan" v-if="finRepayPlan.length>0">
              <i class="el-icon-plus"></i>
            </div>
          </div>
      </div>
       <base-table
        :columns="repaymentPlanConfig"
        :tableAttrs="{
          data: finRepayPlan,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
      >
      <template slot="index" slot-scope="scope">{{scope.$index+1}} </template>
      <template slot="preRepayAmount" slot-scope="scope">
        <amount-input placeholder="请输入" type='Y' v-model="scope.row.preRepayAmount" :disabled="isDetail" />
         </template>
      <template slot="preRepayDate" slot-scope="scope">
        <el-date-picker
      v-model="scope.row.preRepayDate"
      type="date"
      value-format="timestamp"
      :PickerOptions='pickerOptions'
      :disabled="isDetail"
      placeholder="选择日期">
    </el-date-picker>
      </template>
      <template slot="contractRepaymentArr" slot-scope="scope">
        <base-select
        v-model="scope.row.contractRepayment"
        :clearable="true"
        :multiple="true"
        :options="repaymentPlanContract"
        :selectedField="['keyId', 'contractName']"
        @change="changeContract(scope.row.contractRepayment,scope.$index)"
        :disabled="isDetail"
       ></base-select>
      </template>
      <template slot="contractCode" slot-scope="scope">
        <el-tooltip class="item" effect="dark" :content="scope.row.contractCode" placement="top">
          <base-input v-model="scope.row.contractCode" maxlength='500' :disabled="true" ></base-input>
        </el-tooltip>
      </template>
      <template slot="repayCondition" slot-scope="scope">
        <base-input v-model="scope.row.repayCondition" maxlength='500' placeholder="请选择还款合同" :disabled="isDetail" ></base-input>
      </template>
      <template slot="remark" slot-scope="scope">
        <base-input v-model="scope.row.remark" maxlength='100' placeholder="请输入" :disabled="isDetail"></base-input>
      </template>
      <template v-if="!isDetail" slot="action" slot-scope="scope" >
           <icon-button
          @click="delInfo(scope.$index)"
          v-if="scope.row.isAdd"
          content="删除"
          icon="iconfont iconshanchu1"
        />
      </template>
      </base-table>
    </div>
</template>

<script>
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { formatDate } from '@/utils/auth/common'
import { repaymentPlanConfig } from '../utils/config'
import BaseInput from '@/components/input/sz-input/sz-input.vue'
// import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { BaseInput, BaseTable, BaseSelect, IconButton, AmountInput },
  props: {
    finRepayPlan: Array,
    repaymentPlanContract: Array, // 已选择的合同
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() <= new Date('1999-12-31')
        }
      }
    }
  },
  // 计算属性 类似于data概念
  computed: {
    repaymentPlanConfig () {
      return repaymentPlanConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 删除还款计划
    delInfo (index) {
      this.finRepayPlan.splice(index, 1)
    },
    // 新增还款计划
    addPlan () {
      this.finRepayPlan.push({ isAdd: true })
    },
    // 选择合同
    changeContract (data, index) {
      // let value = ''
      // const repaymentPlanContractArr = []
      // data.forEach((elem) => {
      //   // elem 合同id
      //   this.repaymentPlanContract.forEach((item) => {
      //     if (item.keyId === elem) {
      //       if (repaymentPlanContractArr.length === 0) {
      //         repaymentPlanContractArr.push(formatDate(this.finRepayPlan[index].preRepayDate, 'YY/MM/DD') + '/' + item.contractName)
      //       } else {
      //         repaymentPlanContractArr.push(item.contractName)
      //       }
      //     }
      //   })
      // })
      const contractCode = []
      const repayCondition = []
      data.forEach((item, i) => {
        const currentItem = this.repaymentPlanContract.filter(it => item === it.keyId)[0]
        contractCode.push(currentItem.contractName + '/' + currentItem.contractCode)
        if (i === 0) {
          repayCondition.push(formatDate(this.finRepayPlan[index].preRepayDate, 'YY/MM/DD') + '/' + currentItem.contractName)
        } else {
          repayCondition.push(currentItem.contractName)
        }
      })
      this.$set(this.finRepayPlan[index], 'repayContractId', data)
      this.$set(this.finRepayPlan[index], 'repayCondition', repayCondition.join(','))
      this.$set(this.finRepayPlan[index], 'contractCode', contractCode.join(','))
    },
    // 生成
    generateRepaymentPlan () {
      this.$emit('generateRepaymentPlan', '')
    },
    optionDisabled (row) {
      const isDisabled = this.finRepayPlan.some(item => item.contractRepayment === row.keyId)
      return isDisabled
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.otherNotePlan{
  text-align: right;
  padding: 7px 5px ;
  background: #fff;
  display: block;

  /deep/ .el-button{
    width: 90px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    padding: 0;
    position: relative;
    i{
      font-size: 20px;
    }
    span{
      display: inline-block;
      position: relative;
      top: -3px;
    }
  }
}
// 页面按钮
.addbtn {
  display: flex;
  justify-content: flex-end;
}
// 页面按钮
.addbutton {
  background: #4A7CF0;
  width: 20px;
  height: 20px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background-color: #6996FF;
  }
}
/deep/ .el-select__tags-text {
  display: inline-block;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-tag__close.el-icon-close {
  top: -6px !important;
  right: -7px !important;
}

</style>
